.label {
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  font-size: 1.3rem;
  font-weight: 900;
}

.ln-o-grid.ml-spacer {
  margin-left: 0rem;
  margin-bottom: 0.6rem;
}

.ln-o-grid__item.pl-spacer {
  padding-left: 0rem;
}

.overview-container,
.search-workorder-container {
  margin-bottom: 1.5rem;
}

.overview-title {
  margin: 1.5rem 0.7rem;
}

.retry-info-icon {
  color: rgb(215, 0, 0);
  margin-left: 1rem;
  margin-top: -2px;
}

.icon-wrap {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.linked-content {
  text-align: left;
}

.linked-wo-container {
  width: 100%;
  background-color: #f6f6f6;
  padding: 15px;
  position: relative;
  border: 1px solid black;
  margin-bottom: 10px;
  cursor: pointer;
}

.ln-right {
  text-align: right !important;
  margin-top: 20px;
}

.tag-linked {
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid black;
  margin-right: 10px;
}
.m-r-1 {
  margin-right: 1rem;
  display: inline-block;
}
.status-div {
  display: inline-block;
  float: right;
  margin-top: 10px;
}
.time-div {
  display: inline-block;
  margin-top: 10px;
}
.linked-stat {
  margin-left: -5px !important;
}

.img-stat {
  padding-left: 0.8rem;
}

.card-tiles {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(224, 224, 224, 1);
  margin-bottom: 0.5rem;

  &.open-tile {
    padding: 1rem 1.2rem 0.6rem 1.2rem;
    cursor: pointer;
  }
}

.separator-block {
  // border: none !important;
  // background: #f6f6f6 !important;
  margin: 1.8rem 0.5rem;
}

.separator-line {
  border: 0.6px solid #c7c7c7;
}

.OpenProb a,
.ClosedProb a,
.RecalledProb a,
.EscalatedProb a {
  text-decoration: none;
  color: #4c4c4c;
}
.canBeRecalled a,
.OverDueProb a {
  text-decoration: none;
}

.ln-u-bg-color-red,
.ln-u-bg-color-light-red,
.ln-u-bg-color-black,
.ln-u-bg-color-lg-grey {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  //     -webkit-transform: perspective(1px) translateZ(0);
  //   transform: perspective(1px) translateZ(0);
  //   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  //   -webkit-transition-duration: 0.3s;
  //   transition-duration: 0.3s;
  //   -webkit-transition-property: transform;
  //   transition-property: transform;
  //   -webkit-transition-timing-function: ease-out;
  //   transition-timing-function: ease-out;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover::before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  cursor: pointer;
  & p {
    margin-bottom: 0rem;
  }
}

.ln-u-bg-color-red {
  background-color: #db3e34 !important;
  width: 100%;
  color: #ffffff;

  &::before {
    background: #d70000;
  }
}

.ln-u-bg-color-light-red {
  background-color: #f7d8d6;
  width: 100%;

  &::before {
    background: #f6c0bc !important;
  }
}

.ln-u-bg-color-black {
  width: 100%;
  color: #ffffff;

  &::before {
    background: #404040 !important;
  }
}

.ln-u-bg-color-lg-grey {
  background-color: #f6f6f6;
  width: 100%;

  &::before {
    background: #d7d7d7 !important;
  }
}

.overview-page-head {
  margin: 0;
}

// NOTIFICATIONS CSS
.notify-wrap {
  width: 97%;
}
.notification-container {
  display: flex;
  justify-content: space-between;
  background-color: #4c4c4c;
  color: #fff;
}

.alert {
  position: relative;
  background-color: #4c4c4c;
  color: #fff;
  padding: 1.2rem;
  margin-bottom: 1px;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    color: currentColor;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
}

// Visits

.visit-wrap {
  p {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }

  h6 {
    margin-bottom: 0.3rem;
  }
}

.visits-spacer {
  margin-bottom: 2rem;
}

.op-contactor-wrap {
  padding-top: 2rem;
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .notify-title {
    padding-left: 1rem;
  }
  .overview-loader,
  .overview-page-head {
    margin-left: 0.8rem;
  }
}

@media (max-width: 699px) {
  .notify-title {
    padding-left: 1rem;
  }

  .visit-wrap {
    h4 {
      padding-left: 1rem;
    }

    p {
      padding-left: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .alert-wrap {
    margin-top: 3.5rem;
  }

  .overview-title,
  .search-workorder-container {
    margin: 4.8rem 1rem 1.2rem;
  }

  .overview-page-head {
    margin-left: 0.8rem;
  }

  .overview-loader {
    margin-left: 0.8rem;
  }
}
