.ln-c-alert.ln-c-alert--error {
  background-color: #d70000;
  color: #fff;
  padding: 1rem;
}

.toggleModal {
  display: none;
}

.ln-c-alert.ln-c-alert--dismissable {
  padding: 1rem;
  text-align: left;
}

.ln-c-alert--created {
  padding: 1rem;
  text-align: center;
}

.ln-c-alert {
  position: relative;
  background-color: #4c4c4c;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.ln-c-alert.ln-c-alert--error {
  text-align: left;
}

.ln-o-flag {
  align-items: center;
  display: flex;
  width: 100%;
}

.ln-u-margin-right {
  margin-right: 0.5rem !important;
}

.ln-o-flag__component {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.ln-o-flag__body--top,
.ln-o-flag__component--top {
  align-self: flex-start;
}

// .ln-o-flag__body{
//   flex-grow: 0.9;
// }

.ln-c-alert--error .ln-c-alert__dismiss-button,
.ln-c-alert--error
  .ln-c-alert__dismiss-button:not(:disabled):not(.is-disabled):focus,
.ln-c-alert--error
  .ln-c-alert__dismiss-button:not(:disabled):not(.is-disabled):hover {
  color: #fff;
}
.ln-o-flag_success{
  text-align: left;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}

.ln-c-alert__dismiss-button,
.ln-c-alert__dismiss-button:not(:disabled):not(.is-disabled):focus,
.ln-c-alert__dismiss-button:not(:disabled):not(.is-disabled):hover {
  color: #fff;
}

.ln-c-dismiss {
  position: absolute;
}

.ln-c-dismiss.rgtArrow {
  right: 1%;
}

.ln-c-button--hard,
.ln-c-button--icon {
  padding: 0;
}

@media (max-width: 768px) {
  .ln-c-dismiss {
    right: 0.25rem;
  }
}

@media (max-width: 699px) {
  .ln-c-alert.ln-c-alert--dismissable {
    padding: 1.5rem 1.8rem;
  }

  .ln-c-alert.ln-c-alert--error,
  .ln-c-alert {
    margin-top: 3.5rem;
  }

  .ln-c-alert {
    text-align: left;
  }

  .ln-c-dismiss {
    right: 0.25rem;
    top: 0.7rem;
  }
  .ln-o-flag_success {
    text-align: left;
}
}

@media (max-width: 360px) {
  .ln-c-dismiss {
    right: -0.6rem;
    top: 0.7rem;
  }
  .ln-o-flag_success {
    text-align: left;
}
}