.unauthorised-container {
    text-align: center;
    margin-top: 80px;
  }

.unauthorise-colleague-button {
    width: 300px;
    margin-bottom: .5rem;
  }

.unauthorise-inner-container {
   width: 512px;
   margin: 30px auto 0;
  }
  
h5 { margin-bottom: .5rem;}
  
@media (max-width: 719px) {
.unauthorise-inner-container {
      width: auto;
}
}
