.logo {
    width: 8rem;
    margin-bottom: .5rem;
  }

@media (max-width: 719px) {
    .ln-u-flush-top {
        margin-top: 56px !important;
    }
    .positionImage{
        text-align: center;
        margin-bottom: 35px;
        font-size: 20px;
    }
    .verisaelogo{
        text-align: center;
        margin-bottom: 45px;
    }
    .imageLogo{
        height: 91px;;
    }
}

@media only screen and (min-width:768px){
    .ln-c-button-group__primary{
        display: block !important;
    }
    .positionImage{
        text-align: center;
        margin-bottom: 45px;
    }
    .margin-top{
        margin-top: 150px;
    }
    .verisaelogo{
        text-align: center;
        margin-bottom: 45px;
    }
    .imageLogo{
        height: 91px;;
    }
}