.scanTO-container {
    text-align: center;
    margin-top: 80px;
}

.scanTO-rescan-button {
    width: 300px;
    margin-bottom: .5rem;
}

.scanTO-inner-container {
    width: 512px;
    margin: 30px auto 0;
}

.ln-o-bare-list--spaced-sm>.ln-o-bare-list__item {
    padding-bottom: 0;
}

.withOAtag {
    border-bottom: none;
    color: #4c4c4c;
}

h5 { margin-bottom: .8rem;}
  
@media (min-width: 768px){
.scanTO-inner-container {
      width: 512px;
    }
}
  
@media (max-width: 719px) {
.scanTO-inner-container {
      width: auto;
    }
}
  