.awoinfo-container {
    text-align: center;
    margin-top: 60px;
}

.awoinfo-missing-button {
    width: 300px;
    margin-bottom: .5rem;
}

.bld-unline {
    font-weight: bold;
    text-decoration: underline;
}

.awoinfo-inner-container {
    width: 512px;
    margin: 30px auto 0;
}
  
.withOAtag {
    border-bottom: none;
    color: #4c4c4c;
}
h5 { margin-bottom: .8rem;}

.awo-info-circle {
    width: 4.25em;
    height: 4.25em;
}

.a-no-link-style,
.a-no-link-style:hover,
.a-no-link-style:active,
.a-no-link-style:visited {
  color: inherit;
  text-decoration: inherit;
}

@media (min-width: 768px){
.awoinfo-inner-container {
      width: 512px;
    }
}
  
@media (max-width: 719px) {
.awoinfo-inner-container {
      width: auto;
    }
}
  