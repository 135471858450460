.helpdesk-container {
  text-align: center;
  margin-top: 80px;
}
.helpdesk-home-button {
  width: 300px;
  margin-bottom: .5rem;
}

.helpdesk-container-four {
  width: 512px;
  margin: 4rem auto 0;
}

h5 { margin-bottom: .5rem;}

@media (min-width: 768px){
  .helpdesk-container-four {
    width: 512px;
    margin: 4rem auto 0;
  }
}

@media (max-width: 719px) {
  .helpdesk-container-four {
    width: auto;
  }
}
.container {
  overflow: hidden
}
