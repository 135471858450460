.store-search {
    margin-top: 4rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  
    h5 {
      margin-bottom: 1.5rem;
      margin-left: 0.8rem;
    }
    .ln-c-card--soft {
      margin: 0;
    }
  }
  
  .AutoCompleteWrap {
    width: 100%;
  
    input {
      width: 100%;
      box-sizing: border-box;
      outline: none;
    }
  
    ul {
      list-style: none;
      text-align: left;
      margin: 0;
      padding: 0;
    }
  
    li {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
  
  .AutoCompleteWrap li:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }
  
  // .ln-c-button.ln-c-input-group__action {
  //     display: none;
  // }

  
  