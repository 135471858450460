.fivehundred-container {
    text-align: center;
    margin-top: 80px;
  }
  .fivehundred-colleague-button {
    width: 300px;
    margin-bottom: 1.2rem;
  }
  
  .fivehundred-inner-container {
    width: 512px;
    margin: 30px auto 0;
  }
  
  h5 { margin-bottom: .5rem;}
  
  @media (min-width: 768px){
    .fivehundred-inner-container {
      width: 512px;
    }
  }
  
  @media (max-width: 719px) {
    .fivehundred-inner-container {
      width: auto;
    }
}
