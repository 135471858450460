.report-container {
  margin-top: 50px;
}

.rpo-footer-container {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // line-height: 70px;
  width: 300px;
  // background-color: #ffffff;
  // border-top: 1px solid #c2c2c2;
  margin: 4rem auto 2rem auto;
}

.rpo-card-container{
  padding: 0;
  margin-bottom: 1.5rem !important;
}

.rpo-card-content {
  padding: 2rem;
}

.backLink {
  margin: 1rem auto;
  a {
    text-decoration: none;
  }
  span {
    position: absolute;
    margin-left: 0.5rem;
  }
}

.no-wrap{
  white-space: nowrap;
}

.banner-pointer{
  text-decoration: underline;
  cursor: pointer !important;
}

#interactive.viewport {
  position: relative;
}

#interactive.viewport > canvas, #interactive.viewport > video {
  max-width: 100%;
  width: 100%;
}

canvas.drawing, canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.report-problem-button, .view-existing-problem {
  width: 300px;
  margin-bottom: 1rem;
}

.result-container {
  line-height: 1.5rem;

  h5 {
    margin-bottom: 0rem;
  }
  p {
    margin-bottom: 1rem;
  }
}


.provideWrap {
  margin-top: 4rem;
}

.ln-c-alert.newWorkOrder {
  background-color: transparent;
  color: #4c4c4c;
  padding: 0;
  text-align: left;
  margin: 0;

  p {
    width: 85%;
  }
}

.ln-c-button.ln-c-button--hard.ln-c-alert__dismiss-button.ln-c-dismiss.ln-c-icon.greenTick {
    color: green;
    top: -0.25rem;
    min-height: auto;
    min-width: auto;
}

audio,
video {
  width: 100%;
}

.ln-c-field-info--error.ln-c-field-info--validation-first {
  order: 1;
  padding-top: 0.5rem;
  
}

.m-top-1{
margin-top: 1rem !important;
}
.ln-c-field-info--error::before {
  background: #d70000 !important;
} 


.charCount {
  margin-top: -1.8rem;
  margin-bottom: 1.5rem;
}

// Duplicate
.view-duplicate-button {
  width: 300px;
}

.icon-container {
    height: 50px;
}

.ln-c-progress-bar__fill {
  transition: width 2s ease-in-out;
}

.nextMarginTop, .uploadMarginTop {
  margin-top: 1rem;
}

.tel-number {
  font-size: 2rem;
  line-height: 1.25;
  font-family: MaryAnn, Trebuchet MS, sans-serif;
  font-weight: 700;
}

span a,
span a:hover {
  color: #4c4c4c;
  text-decoration: none;
}
.wrapper {
	text-align: center;
  margin: 0 auto;
  padding-bottom: 1.5rem;

  img {
    width: 100%;
  }
  }

  .steppageTitle {
    margin: 0rem 0 0rem 1rem;
  }

  .steps{
    margin-left: 15px;
    margin-bottom: 20px;
  }
.contentWrap {
  position: absolute;
  left: 40%;

  h1 {
    text-align: left;
    margin-bottom: 0;
  }

  p {
    text-align: left;
    width: 65%;
    line-height: 1.2rem;
  }
}

.deskImage {
  display: block;
}

.mobileImage {
  display: none;
}

@media (max-width: 1024px) {
  .contentWrap {
    position: absolute;
    left: 38%;
  }
}

@media (max-width: 768px) {
  .ln-u-display-flex {
    margin: auto 0.5rem;
  }

  .contentWrap {
    position: absolute;
    left: 35%;
  }

  audio,
  video {
    margin: auto;
  }

  .report-container {
    margin-top: 80px;
  }

  .backLink {
    margin-left: 0.8rem;
    a {
      text-decoration: none;
    }
    span {
      position: absolute;
      margin-left: 0.5rem;
    }
  }

  .result-container {
    h5 {
      margin-bottom: 0rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 699px) {

  .ln-c-alert.ln-c-alert--dismissable.ln-c-alert--squared.notification-bar{
    margin-top: 0;
  }

  .ln-u-display-flex {
    margin: auto 0.5rem;
  }

  .deskImage {
    display: none;
  }

  .mobileImage {
    display: block;
  }

  .overflow {
    overflow: hidden;
  }

  .backLink {
    margin-top: 5rem;
    margin-left: 1rem;
    a {
      text-decoration: none;
    }
    span {
      position: absolute;
      margin-left: 0.5rem;
    }
  }

  audio,
  video {
    margin-left: 0rem;
  }

  .result-container {
    h5 {
      margin-bottom: 0rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}


@media only screen and (max-width: 812px) and (min-width: 736px) {
  .ln-u-display-flex {
    margin: auto 0.5rem;
  }

  .report-container {
    margin-top: 40px;
  }
  .contentWrap {
    left: 32%;
  }
}

// @media only screen 
// and (min-device-width : 568px) 
// and (max-device-width : 812px) 
// and (orientation : landscape) { 
//   .rpo-footer-container {
//   position: relative;
// }
// }

@media only screen 
and (min-device-width : 360px) 
and (max-device-width : 640px) 
and (orientation : portrait) { 
  .ln-u-display-flex {
    margin: auto 0.5rem;
  }

  .contentWrap {
    left: 13%;
  }
 }

@media only screen and (max-width: 735px) and (min-width: 568px) {
  .ln-u-display-flex {
    margin: auto 0.5rem;
  }

  .contentWrap {
    left: 20%;
  }

  h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 415px) and (min-width: 375px) {
  .ln-u-display-flex {
    margin: auto 0.5rem;
  }
  
  .contentWrap {
    left: 13%;
  }

  h1 {
    font-size: 3.5rem;
  }
}

.notification-bar{
  u{
    text-decoration: underline;
  }
}
