.ImgContainer{
    position:relative;
    // margin: 2px 37px 25px 0px;
    display: inline-block;
}
#imgtag {
    width:100% !important;
    height:200px !important;
}
.icon-top-left{
    position: absolute; 
    border: 1px solid #f06c00;
    color: #f06c00;
    background-color: white;
    top:5px;
    right: 5px;
}

.rp_cancel_btn {
    color: #4c4c4c;
   margin-top: 1rem;
}

.pfalign {
    width: 95%;
    word-wrap: break-word;
}