.generic-container {
    text-align: center;
    margin-top: 80px;
  }
  .generic-home-button {
    width: 300px;
    margin-bottom: .5rem;
  }
  .generic-inner-container {
    width: 512px;
    margin: 30px auto 0;
  }
  
  h5 { margin-bottom: .5rem;}
  
  @media (min-width: 768px){
    .generic-inner-container {
      width: 512px;
      margin: 30px auto 0;
    }
  }
  
  @media (max-width: 719px) {
    .generic-inner-container {
      width: auto;
      margin: 30px auto 0;
    }
  }
  