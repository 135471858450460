.pageHeading {
  margin: 2rem auto;
}

.op-disable {
  pointer-events: none !important;
  opacity: 0.5;
  background: #ccc;
}

.op-container {
  margin-top: 1.5rem;
}

.op-container a {
  text-decoration: none;
}
.op-container a:hover,
.op-container a:focus {
  color: #4c4c4c;
}

.op-spacer {
  margin-bottom: 0.5rem;
  display: flex;
}

.opp-card-warning {
  border-left: 3px solid #d70000 !important;
}

.ln-u-color-grey {
  color: #8e8e8e;
}

.notiPageTitle {
  margin: 0rem 0 0rem 1rem;
}

.pageSubHeading {
  margin-bottom: 1.5rem;
}

// Open Problems Card

.opp-card-mb {
  margin-bottom: 2rem !important;
}

.opp-card-container {
  margin: 0;
  padding: 0;
}

.opp-card-content {
  padding: 1.5rem;
}

.ln-u-border-ends.opp-btb {
  border-top: 1px solid #b9b9b9 !important;
  border-bottom: 1px solid #b9b9b9 !important;
}

.ln-u-border-bottom.opp-bb {
  border-bottom: 1px solid #b9b9b9 !important;
}

.ln-u-soft.ln-u-border-top.opp-topb {
  padding: 1.5rem !important;
  border-top: 1px solid #b9b9b9 !important;
}

// Modal
.addPhoto img {
  width: 100%;
}

.ln-c-modal__scroll-area img {
  width: 100%;
}

.ln-u-margin-left.icon-top-left.closeBtn {
  border: 2px solid #f06c00;
  top: 2.5rem;
  right: 2.5rem;
  width: 30px;
  height: 30px;
}

// Recall work

.rw-wrap {
  text-align: center;
}

.rw-container {
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.rw-width {
  width: 40%;
}

.CameraWidth {
  height: 48px;
}

// .is-disabled, :disabled.rw-hide {
//     display: none;
// }

.rw-hide {
  display: none;
}

// Tags classes
.tag_default {
  background-color: #f2f2f2;
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #737373;
  color: #4c4c4c;
}
.tag_being_fixed {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #0c0c0c;
  margin-right: 1rem;
}

.tag_fix_incomplete {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #0c0c0c;
  margin-right: 1rem;
}

.linked_wo {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #f06c00;
  color: #f06c00;
  display: inline-block;
}

.tag_fix_complete {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #218234;
  display: inline-block;
  margin-right: 1rem;
}

.tag_review_fix {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #218234;
  margin-right: 1rem;
}

.tag_cancelled {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border: 1px solid #d70000;
  margin-right: 1rem;
}

.ln-c-progress-indicator.is-loading.loadWidth {
  width: 18%;
}

.progress {
  margin: auto 41%;
}

.FMHelpDesk-Underline {
  text-decoration: underline;
}

.ln-o-flag__component.loadmr {
  margin-right: 1rem;
}

.op-prblm-wrap {
  margin-bottom: 1rem;
}

.dueDate-container {
  margin: 1rem auto;
}

.opp-imgContainer {
  margin: 1.5rem auto;
}

.opp-width {
  width: 100%;
}

.rgt-arrow {
  align-self: center;
}

.opp-problemDescription {
  margin-top: 1.5rem;
}

// History comments

.opp-hist-container {
  border-bottom: 1px solid #b9b9b9;
  padding: 1.5rem;
}

.opp-hist-container ul {
  margin: 0;
}

.opp-hist-description {
  margin: 1rem auto;
  overflow-wrap: break-word;
  word-break: break-word;
}

.desc-overflow-wrap {
  overflow-wrap: break-word;
  word-break: break-word;
}

.alignRight {
  text-align: right;
}

.td-overflow-wrap {
  word-break: break-word;
}

// Problem information

.opp-pd-container {
  padding: 1.5rem;
}

// Custom Filters
.ln-c-toggle-button-group:focus-within
  .ln-c-toggle-button:not(:disabled):not(.is-disabled) {
  border-color: #f06c00;
  color: #f06c00;
}

.ln-c-toggle-button-group:focus-within
  .ln-c-toggle-button:not(:disabled):not(.is-disabled).is-active {
  background-color: #f06c00;
}

.ln-c-toggle-button-group:hover
  .ln-c-toggle-button:not(:disabled):not(.is-disabled).is-active {
  background-color: #f06c00;
}

.ln-c-toggle-button-group:hover
  .ln-c-toggle-button:not(:disabled):not(.is-disabled) {
  border-color: #f06c00;
  color: #f06c00;
}

// Add Comments and Photo
.acp-card-content {
  padding: 1.5rem;
}

.acp-bt-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.acp-camera-bg {
  background: transparent;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pageHeading {
    margin: 2rem 0.5rem;
  }

  .op-filter-wrap {
    margin: auto 0.5rem;
  }

  .rw-width {
    width: 40%;
  }

  .opp-border-mob {
    border: 1px solid #b9b9b9;
    margin: auto 0.5rem;
  }
}

// @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
//     .op-container {
//         margin-top: 4rem !important;
//     }

// }

@media screen and (max-width: 1024px) {
  .rgt-arrow {
    // width: 70%;
  }
}

// @media screen and (max-width: 813px) {
//     .op-container {
//         margin-top: 1rem;
//     }

//     .rgt-arrow {
//        // width: 90%;
//     }
// }

@media (min-width: 568px) and (max-width: 737px) {
  .ln-u-margin-left.icon-top-left.closeBtn {
    border: 2px solid #f06c00;
    top: 1.5rem;
    right: 1.5rem;
    width: 30px;
    height: 30px;
  }

  // .op-container {
  //     margin-top: 1rem;
  // }

  .op-filter-wrap {
    margin: auto 0.5rem;
  }

  .pageHeading {
    margin: 2rem 0.5rem;
  }
  .rgt-arrow {
    // width: 90%;
  }
  .rw-width {
    width: 100%;
  }
  .CameraWidth {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 415px) {
  .ln-u-margin-left.icon-top-left.closeBtn {
    border: 2px solid #f06c00;
    top: 1.5rem;
    right: 1.5rem;
    width: 30px;
    height: 30px;
  }

  .opp-border-mob {
    border-left: 0;
    border-right: 0;
  }

  // .op-container {
  //     margin-top: 1rem;
  // }

  .op-filter-wrap {
    margin: auto 0.5rem;
  }

  // .op-container {
  //     margin-top: 4.5rem;
  // }

  .pageHeading {
    margin: 2rem 0.5rem;
  }
  .rgt-arrow {
    // width: 85.5%;
  }
  .rw-width {
    width: 100%;
  }
  .CameraWidth {
    width: 100%;
  }
  .linked_wo {
    margin-top: 10px;
  }
}
.margin-bottom-card {
  margin-bottom: 0.5rem;
}
