.wrongbarcode-container {
    text-align: center;
    margin-top: 80px;
}
.wrongbarcode-rescan-button {
    width: 300px;
    margin-bottom: .5rem;
}
.wrongbarcode-inner-container {
    width: 512px;
    margin: 30px auto 0;
}
  
.withOAtag {
    border-bottom: none;
    color: #4c4c4c;
}
h5 { margin-bottom: .8rem;}
  
@media (min-width: 768px){
.wrongbarcode-inner-container {
      width: 512px;
    }
}
  
@media (max-width: 719px) {
.wrongbarcode-inner-container {
      width: auto;
    }
}
