.unsupported-content {
    text-align: left;
    @include ln-respond-to('sm') {
        text-align: center;
    }

    .unsupported-content-image {
        padding-left: 3.5vw;
        padding-right: 3.5vw;
        width: auto;
        @include ln-respond-to('md') {
            padding-left: 1.25vw;
            padding-right: 1.25vw;
            width: auto;
        }
        height: 7vh;
    }
    
    .unsupported-content-confirm-button {
        min-width: 125px;
        margin: 0 auto;
        display: block;
    }
}