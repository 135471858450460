.fourOfour-container {
    text-align: center;
    margin-top: 80px;
  }
.fourOfour-home-button {
    width: 300px;
    margin-bottom: .5rem;
  }

.fourOfour-container-four {
    width: 512px;
    margin: 30px auto 0;
  }
  
h5 { margin-bottom: .5rem;}
  
@media (min-width: 768px){
    .fourOfour-container-four {
      width: 512px;
      margin: 30px auto 0;
    }
  }
  
@media (max-width: 719px) {
    .fourOfour-container-four {
      width: auto;
    }
  }
  .container {
    overflow: hidden
  }
  