.r-findworkorder-overview-container {
    display: flex;
    width: auto;
}

.dm__filter-panel-wrapper {
    background-color: white;
}

.r-filter-panel {
    position: sticky;
    top: 100px;
    height: 90vh;
    overflow-y: auto;
    width: auto;
}

.container-width {
    width: 40rem;
    margin: 0 auto
  }

.search-workorder-title {
    margin: 1.5rem 0.1rem;
  }

@media screen and (max-width: 720px) {
    .r-filter-panel {
        display: none;
    }

    .r-filter-header-desktop {
        display: none;
    }
}