$ln-webpack: true; // or $ln-fonts-url: '/assets/fonts'; if it's a non-webpack project
$ln-theme: 'sainsburys';
$ln-border-size-large: 2px;
// @import '@jsainsburyplc/retail-shared-components/scss/global.scss';
@import '~@jsluna/style/scss/main';

@import './components/logo';
@import './components/generic_error';
@import './components/session';
@import './components/unauthorised';
@import './components/fourOfour';
@import './components/fivehundred';
@import './components/awoinfo';
@import './components/scantimeout';
@import './components/wrongbarcode';
@import './components/report_problem';
@import './components/wo_status';
@import './components/helpdesk';
@import './components/scan_asset';
@import './components/preRequesite';
@import './components/whereistheproblem';
@import './components/changestore';
@import './components/openproblem';
@import './components/fixedProblems';
@import './components/unsupportBrowser';
@import './components/overview';
@import './components/typography';
@import './components/loading';
@import './components/filterpanel';
@import './components/filterpills';
@import './components/navigationBar';

.headerCurrentUser {
  text-decoration: none !important;

  &:hover {
    color: #4c4c4c;
  }
}

.recall-rejected {
  text-decoration: underline;
  cursor: pointer;
}

.warning-alert {
  background-color: #b50000 !important;
}

.d-block {
  display: inline-block !important;
}

.icon-span {
  display: block;
}

.icon-alignment {
  margin-top: -50px !important;
}

.icon-right {
  margin-right: 1rem !important;
}

.text-align {
  text-align: left !important;
}

.second-div {
  margin-left: 2.8rem;
}

.deskdecoration {
  text-decoration: underline;
}

.ln-c-navigation-wrapper__inner {
  float: left !important;
}
.ln-c-navigation-wrapper__title a {
  text-decoration: none;
}

.u-tutorial-content-container h4 {
  font-size: 1.4rem;
}

.ln-c-dismiss {
  right: 22.5%;
}

.ln-c-header .ln-c-topbar__list {
  text-align: right;
}

.tick-icon {
  border: 1px solid;
  border-radius: 100%;
  padding: 0px;
  width: 5%;
  border-color: #2e7d32;
  background-color: #2e7d32;
  color: #fff;
  height: 5%;
}

.exclamation-icon {
  color: #d50000;
  width: 5%;
  height: 5%;
}

.icon-div {
  text-align: center;
  margin-top: 1rem;
}

.d-unset {
  display: unset;
}

.dpndnt-quest {
  border-left: 4px solid #f06c00;
}

.m-l-2 {
  margin-left: 1.5rem;
}

.dpndnt-div {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.dpndnt-quest {
  border-left: 4px solid #f06c00;
}

.m-l-2 {
  margin-left: 1.5rem;
}

.dpndnt-div {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.dpndnt-quest {
  border-left: 4px solid #f06c00;
}

.m-l-2 {
  margin-left: 1.5rem;
}

.dpndnt-div {
  width: 100%;
}

.w-100 {
  width: 100%;
}
.dpndnt-quest {
  border-left: 4px solid #f06c00;
}

.m-l-2 {
  margin-left: 1.5rem;
}

.dpndnt-div {
  width: 100%;
}

.w-100 {
  width: 100%;
}



@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .ln-c-dismiss {
    right: 9%;
  }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .ln-c-dismiss {
    right: 0%;
  }
}

@media (max-width: 768px) {
  .ln-c-dismiss {
    right: 0%;
  }
}

@media (max-width: 699px) {
  .ln-c-dismiss {
    right: 0%;
  }

  .ln-c-header .ln-c-topbar__list {
    text-align: left;
  }

  .second-div {
    margin-left: unset !important;
  }

  .icon {
    width: 10%;
    height: 10%;
  }
}
