.r-filter-pill {
    min-height: 28px;
    min-width: 2px;
    padding: 7px 5px 7px 7px;
    margin-right: 14px;
    border-radius: 10px;
    border: 0.01rem solid;
}

.r-more-filters-button {
    min-height: 28px;
    min-width: 2px;
    padding: 7px 5px 7px 7px;
    margin-right: 14px;
    border-radius: 10px;
    border: 0.01rem solid;
}

@media screen and (min-width: 720px) {
    .r-more-filters-button {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .ln-o-container--xs.r-pageName {
        margin-top: 3rem !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 720px) {
    .r-filter-mobile {
        margin-top: 50px !important;
        padding: 0px !important;
    }
}