.fp-u-bg-color-white {
    background-color: #fff;
    margin-bottom: 1rem;
  }

  .fp-dp-cont{
    margin-bottom: 1.3rem;
  }

  .fp-page-head {
    margin-left: 0rem;
  }

  .fp-spacer {
    margin-bottom: 0.5rem;
  }

  .fp-render-item ul li {
    list-style: none;
  }

  .fp-dp-cont-desk{
    display: block;
  }

  .fp-dp-cont-mob {
    display: none;
  }
  
  .fp-navigation-bar {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .fp-btn-fill {
    text-align: right;
    padding-right: 1rem;
  }
  
  .fp-navigation-list {
    display: table-row;
    float: none;
  }
  
  .fp-navigation-item {
    display: table-cell;
    text-align: center;
    float: none;
  }
  
  .ln-c-button--text.fp-selectDate {
    color: #0c0c0c;
  }

  .ln-c-pagination.ln-c-pagination--first-last.fp-pagRight {
    text-align: center;
    margin-bottom: 1rem;
  }
  

  .fp-container :last-child ul {
    margin-left: 0;
  }

    @media only screen 
  and (max-device-width : 736px) 
  and (orientation : landscape) { 
    .fp-home-btn {
      margin: auto .5rem;
    }
  }
    
    @media only screen 
  and (max-device-width : 768px) 
  and (orientation : portrait) { 
    .fp-home-btn {
      margin: auto .5rem;
    }
  }

  @media (max-width: 719px) {
    .fp-home-btn{
      margin: auto .5rem;
    }
    .fp-c-header-body {
      margin-top: 56px;
    }
    .fp-fill {
      width: 95%;
      margin-left: 1rem;
    }
    .fp-dp-cont-desk {
      display: none;
    }
    .fp-dp-cont-mob {
      display: block;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .fp-dp-cont {
        margin-left: 0.8rem;
    }
    .fp-page-head {
      margin-left: 0.8rem;
    }
  }  

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  .fp-dp-cont {
        margin-left: 0.8rem;
    }
    .fp-page-head {
      margin-left: 0.8rem;
    }
} 

@media (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .fp-dp-cont{
    margin-left: 0.8rem;
  }
  .fp-page-head {
    margin-left: 0.8rem;
  }
}

@media (min-width: 320px) and (max-width: 415px) {
  .fp-dp-cont{
    margin-left: 0.8rem;
  }
  .fp-page-head {
    margin-left: 0.8rem;
  }

}
