.pageTitle {
  margin: 1.2rem auto;
}

.ln-c-button--filled.ln-c-button--dark.scan {
  background-color: #f06c00;
  color: #ffffff;
}

.ln-c-button--filled.ln-c-button--dark:not(:disabled):not(.is-disabled):hover{
  background-color: #f06c00;
  color: #ffffff;
}

.ln-c-card--soft {
  border: 1px solid #b9b9b9;
}

.errorWrap {
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 20px;
}

.errorIcon {
  color: #d70000;
  margin-right: 5px;
}

.errorMessage {
  color: #333333;
  margin-right: 5px;
 }

 .card-space{ margin:0;}
 .asset-title { margin-bottom: 1.5rem;}

 .asset-description{margin-top: 1rem; margin-bottom: 2rem;}

 @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ln-c-card--soft {
    margin: auto 0.5rem;
  }
  .nat-container {
    margin: auto 0.5rem;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) {
  .pageTitle {
    margin-left: 0.8rem;
  }
}

 @media only screen and (max-device-width : 736px) {
  .ln-c-card--soft {
    margin: auto 0.5rem;
  }
  .nat-container {
    margin: auto 0.5rem;
  }
 }

 

 @media (max-width: 719px) {
  .ln-c-card--soft {
    margin: auto 0.5rem;
  }
  .pageTitle {
    margin: 1.2rem 1rem;
  }
  .nat-container {
    margin: auto 0.5rem;
  }
  .card-space {
    margin: 0;
  }
 }