.sessionTimeOut-container {
    text-align: center;
    margin-top: 80px;
  }
  .sessionTimeOut-refresh-login-button {
    width: 300px;
    margin-bottom: .5rem;
  }
  .sessionTimeOut-inner-container {
    width: 512px;
    margin: 30px auto 0;
  }
  
  h5 { margin-bottom: .5rem;}
  
//   @media(min-width:768px){
//     .sessionTimeOut-inner-container {
//       width: 512px;
//       margin: 30px auto 0;
//     }
    
//   }
  
  @media (max-width: 719px) {
    .sessionTimeOut-inner-container {
      width: auto;
      margin: 30px auto 0;
    }
    
  }